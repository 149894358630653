<template>
  <v-card v-if="!loading">
    <v-img :alt="alt" :src="src" contain max-height="200" max-width="200"/>
  </v-card>
  <v-progress-circular v-else indeterminate/>
</template>

<script>

export default {
  name: 'KurccImagePreview',
  props: {
    src: {
      required: true
    },
    alt: {
      type: String,
      default: 'image'
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
